export const initialState = {
  isLogin: false,
  userInfo: {
    user_id: -1,
    username: '게스트',
    bio: '정보를 불러오고 있습니다',
    badge_id: -1,
    badges: [],
    is_admin: false,
  },
};
